/**
 * @flow
 */
import axios from "axios";
import { getErrorMessage } from '../common/index';
process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0"; // To Skip SSL validation
axios.defaults.withCredentials = true

const parseErrorResponse = (err) => {
  let status = null
  try {
    const responseDataErrors = err?.response?.data?.errors
    const errors = responseDataErrors.find((error) => {
      const errorMsg = error?.message ?? ''
      return errorMsg.includes('INVALID_TOKEN') || errorMsg.includes('Access token missing') 
    })
    if (errors) {
      status = 'INVALID_TOKEN'
    }
  } catch (error) {
    console.error('parseErrorResponse.error', error)
  }
  return status
}
export const graphqlAPIHandler = (path, query, headers) => {
  return axios
    .post(`${path}`, {
      query
    }, { headers: headers }, { timeout: 45000 }).then(response => {
      return checkGraphqlResponse(response)
    }).catch(err => {
      return parseErrorResponse(err);
    });
}

export const graphqlAPIHandlerWithVar = (path, headers, query, operationName, variables) => {
  return axios
    .post(`${path}`,
      { query, variables, operationName }
      , { headers: headers }, { timeout: 45000 }).then(response => {
        return checkGraphqlResponse(response)
      }).catch(err => {
        return parseErrorResponse(err);
      });
}

const checkGraphqlResponse = (response) => {
  var noData = null;
  if (response.status === 200) {
    return response.data.data;
  } else {
    return noData;
  }
}

const printErrorConsole = (path, data, headers) => {
  console.error("path==>", path, "data==>", data, "headers==>", headers);
}

const checkRestApiResponse = (response) => {
  var noData = null;
  if (response.status === 200 && response.data.responseCode === 200) {
    return response.data.responseData;
  } else {
    return noData;
  }
}

export const restAxiosPostHandler = (path, data, headers) => {
  return axios
    .post(`${path}`, data,
      { headers: headers }).then(response => {
        const res = checkRestApiResponse(response);
        if (!res) {
          printErrorConsole(path, data, headers);
        }
        return res;
      }).catch(error => {
        printErrorConsole(path, data, headers);
        return null;
      });
}
export const restAxiosGetHandler = (path, data, headers) => {

  return axios.get(`${path}`, { headers: headers }).then(response => {
    if (response) {
      return response;
    } else {
      printErrorConsole(path, data, headers);
      return null;
    }
  }).catch(error => {
    printErrorConsole(path, data, headers);
    return null;
  });
}

export const restFecthPostHandler = (path, requestOptions) => {
  return fetch(
    `${path}`,
    requestOptions
  ).then(response => {
    return response;
  }).catch(error => {
    return getErrorMessage(error);
  });
}

export const restAxiosPutHandler = (path, data, headers) => {
  return axios.put(`${path}`, data,
    headers).then(response => {
      const res = checkRestApiResponse(response);
      if (!res) {
        printErrorConsole(path, data, headers);
      }
      return res;
    }).catch(error => {
      printErrorConsole(path, data, headers);
      return null;
    });
}
